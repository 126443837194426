import React from 'react'
import GalleryBackground from '../../components/galleryBackground'
import GalleryGridFancy from '../../components/galleryGridFancy'
import bookCoverRo from "../../images/rollovers/graphic-design/bookcover-ro.png"
import "../layout.css"

import img1 from '../../images/graphic-design/book-cover/1.jpg'
import img2 from '../../images/graphic-design/book-cover/2.jpg'
import img3 from '../../images/graphic-design/book-cover/3.jpg'
import img4 from '../../images/graphic-design/book-cover/4.jpg'
import img5 from '../../images/graphic-design/book-cover/5.jpg'
import img6 from '../../images/graphic-design/book-cover/6.jpg'
import img7 from '../../images/graphic-design/book-cover/7.jpg'
import img8 from '../../images/graphic-design/book-cover/8.jpg'

export default function BookCover() {
    let images = [img1, img5, img8, img4, img2, img6, img7, img3]

    return (
        <GalleryBackground title="Book Covers" imgSrc={bookCoverRo} >
            <GalleryGridFancy imgArray={images} />
        </GalleryBackground>
    )
}